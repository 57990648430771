"use client";

import Script from "next/script";
const Clarity = () => {
  if (process.env.NEXT_PUBLIC_CLARITY_ID === undefined || process.env.NEXT_PUBLIC_CLARITY_ID === "XXXXXXXXX") return null;
  return <Script id="microsoft-clarity-init" strategy="afterInteractive" dangerouslySetInnerHTML={{
    __html: `
                (function(c,l,a,r,i,t,y){
                    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                })(window, document, "clarity", "script", "p11uoq6nkj");
                `
  }} data-sentry-element="Script" data-sentry-component="Clarity" data-sentry-source-file="Clarity.tsx" />;
};
export default Clarity;